.modal {

    top: 0;
    width: 100vw !important;
    max-width: unset;

    @include breakpoints(sm) {
        width: auto !important;
        top: 20;
    }

    &--header {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: hidden;
    }

    &--content {
        display: flex;
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        padding: 0;
    }

    &--close {
        position: absolute;
        right: 8px;

        :hover {
            background-color: $lightcolor;
            border-radius: 24px;
        }
    }

    .ant-modal,
    .ant-modal-content {
        margin: 0;
        padding: 0;
        border-radius: 0;
        top: 0;
    }

    .ant-modal-body {
        display: flex;
        padding: 0px;
        max-height: 100vh;
    }

    .ant-modal-title {
        text-align: center;
    }
}