.card-carousel {
    $el: &;

    &--container {
        border: none;
        cursor: default;
        border-radius: 0;
        box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
        overflow: hidden;
        width: auto;
        margin: 8px;

        .ant-card-cover {
            margin-top: 0 !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .ant-card-body {
            padding: 8px;
        }
    }

    &--image {
        object-fit: cover;
        margin-bottom: 8px;
        width: 100%;
        height: 100%;
        border-radius: 16px 16px 0 0;
    }

    &--title {
        @extend %title-base;

        font-size: 20px !important;
        line-height: 25px !important;
        margin-bottom: 8px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--text {
        font-weight: 500 !important;
        text-align: left;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--button {
        min-height: 33px;
        width: 115px;
        border-radius: 8px;
        color: $background;
        border: none;
        margin-bottom: 10px;
    }

    &--hide {
        display: none;
    }

    &--swipe {
        justify-content: center;
        align-items: center;
        display: flex;
        height: 180px;
        background-color: transparent;
        border: none;
        margin: 0;
    }

    &--swipe-image {
        object-fit: contain;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;

        &.swipe-format {
            object-fit: cover;
            margin-bottom: 0;
            width: auto;
        }
    }
}

%title-base {
    font-weight: bold !important;

    ::first-letter {
        text-transform: uppercase;
    }
}