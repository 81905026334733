body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ant-btn {
    border-radius: 0 !important;
}

.ant-image {
    position: unset;
    display: block;
}

.ant-image-preview-operations-operation-rotateRight, 
.ant-image-preview-operations-operation-rotateLeft,
.ant-image-preview-operations-operation-flipX,
.ant-image-preview-operations-operation-flipY {
    display: none;
}

// ---- Modal Global Style ----
.ant-modal {
    .ant-modal-close {
        width: auto;
        height: auto;
    }

    .ant-modal-close-x {
        line-height: normal;
    }
}

// ---- Select Global Style ----
.ant-select-selection-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after {
    display: none;
}

#root {
    $primary-antd: var(--scrollbar-color);
    background-color: white;
}

::-webkit-scrollbar {
    /* Remove scrollbar space */
    width: 0;
    /* Optional: just make scrollbar invisible */
    background: transparent;

}

h2 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 24px;
    color: inherit;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.1;
}

// ---- Remove weird animation from antd ----
.ant-btn > div {display: none !important;}