.carousel {
    &--container {
        width: 100%;
        flex: 1;
        display: flex !important;
        align-items: center;
        overflow-y: auto;

        .slick-list,
        .slick-track {
            min-width: 100%;
        }

        .slick-track {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-bottom: 24px;
        }

        .slick-slide {

            &>div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .slick-slide {
            height: auto;
        }

        .slick-dots {
            bottom: 0px;
            margin-bottom: 8px;
        }
    }

    &--container-no-swipe {
        .slick-track {
            gap: 16px;
            padding-bottom: 8px;
        }
    }

    &--arrow {
        cursor: pointer;
        position: absolute;
        display: flex;
        height: 2rem !important;
        width: 2rem !important;
        background: $background !important;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

        svg {
            font-size: 13px;
            color: var(--scrollbar-color);
        }

        &:before {
            display: none;
        }
    }

    &--left {
        left: 4px !important;
        z-index: 999;

        @include breakpoints(sm) {
            left: 5px !important;
        }
    }

    &--right {
        right: 4px !important;
        z-index: 999;

        @include breakpoints(sm) {
            right: 5px !important;
        }
    }

}